import React from "react"
import { graphql } from "gatsby"
import Nav from "../components/nav"
import Footer from "../components/footer"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Stories = ({ data,location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content
  
  return (
    <Layout>
      <Nav urlParam={url_param}/>
      <div className="masthead relative">
        <div className="masthead hidden lg:block">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_desktop
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="masthead block lg:hidden">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_mobile
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        {/* <div className="relative md:absolute text-black md:text-white top-0 left-0 w-full h-full">
          <div className="container py-5 md:mt-36">
            <h1 className="font-light text-2xl md:text-4xl xl:text-5xl">
              {data.allMdx.edges[0].node.frontmatter.masthead_line1 !== ""}
              <span className="inline md:block">
                {data.allMdx.edges[0].node.frontmatter.masthead_line1}
                {data.allMdx.edges[0].node.frontmatter.masthead_line2 !==
                  "" && <span className="inline">&nbsp;</span>}
              </span>
              {data.allMdx.edges[0].node.frontmatter.masthead_line2 !== "" && (
                <span className="inline md:block md:mt-1">
                  {data.allMdx.edges[0].node.frontmatter.masthead_line2}
                </span>
              )}
            </h1>
          </div>
        </div> */}
      </div>
      <section className="section sectionFirst">
        <div className="container py-5">
          <div className="about-description">
            
          <h2 className="text-xl md:text-3xl xl:text-4xl font-light uppercase mb-10">BMW Eurokars Auto Stories</h2>
            
            <p className="mb-5 lg:pr-5">
            Welcome to the BMW Eurokars Auto Stories page. Dive into a collection of compelling narratives that breathe life into BMW’s iconic vehicles, showcasing their sleek design and heart-pounding performance Join our community of enthusiast and experience the allure of BMW – its not just a drive, it’s a lifestyle. 
            </p>

          </div>
        </div>
      </section>
      <section className="section">
      <div className="container">
        <div className="row"> 
            <div className="col-12 col-md-4">
            <Link
                to={"/stories-dynamic-harmony/" + url_param}
                data-gtm-category="BMW Stories"
                data-gtm-action="Clicked_Image"
                data-gtm-label="View Story">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.stories1
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </Link>
                <br />
                <p classname="pb-3">
                <Link
                to={"/stories-dynamic-harmony/" + url_param}
                data-gtm-category="BMW Stories"
                data-gtm-action="Clicked_Image"
                data-gtm-label="View Story">
                <strong>Dynamic Harmony: The BMW i7 M70 – A two-toned luxury ride</strong></Link>
                <br /><br />
                The BMW i7 M70 stands out as a masterpiece, seamlessly blending cutting-edge technology with an exquisite aesthetic. This visionary masterpiece from BMW not only redefines the driving experience but also elevates the concept of luxury to new heights.<br /><br />
                </p>
            </div>
            <div className="col-12 col-md-4">
            <Link
                to={"/stories-driving-sustainability/" + url_param}
                data-gtm-category="BMW Stories"
                data-gtm-action="Clicked_Image"
                data-gtm-label="View Story">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.stories2
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </Link>
                <br />
                <p classname="pb-3">
                <Link
                to={"/stories-driving-sustainability/" + url_param}
                data-gtm-category="BMW Stories"
                data-gtm-action="Clicked_Image"
                data-gtm-label="View Story"><strong>Driving Sustainability: A Close Look at The Eco-Friendly BMW Eurokars Auto Grand Klasse Ball</strong></Link><br /><br />
                Merely two months ago, the BMW Eurokars Auto Grand Klasse Ball demonstrated a meticulous commitment to sustainability. Held at The St. Regis Singapore's Caroline Mansion, the event showcased scrupulous planning aligned with sustainability principles, from conceptualisation to material selection.<br /><br />
                </p>
            </div>
            <div className="col-12 col-md-4">
            <Link
                to={"/stories-bmw-experience-centre/" + url_param}
                data-gtm-category="BMW Stories"
                data-gtm-action="Clicked_Image"
                data-gtm-label="View Story">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.stories3
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </Link>
                <br />
                <p classname="pb-3">
                <Link
                to={"/stories-bmw-experience-centre/" + url_param}
                data-gtm-category="BMW Stories"
                data-gtm-action="Clicked_Image"
                data-gtm-label="View Story"><strong>An Extravagant Retreat: The BMW Eurokars Experience Centre Customer Lounge</strong></Link><br /><br />
                Nestled within the heart of luxury and automotive innovation, the BMW Eurokars Experience Centre unveils a customer lounge that transcends conventional expectations. Elevating the service experience to new heights, this haven of sophistication offers patrons a unique blend of culinary delights, premium beverages, and expert assistance, making each visit an indulgent affair.<br /><br />
                </p>
            </div>
        </div>
        <div className="row"> 
            <div className="col-12 col-md-4">
            <Link
                to={"/stories-charting-a-course-for-luxury-and-innovation/" + url_param}
                data-gtm-category="BMW Stories"
                data-gtm-action="Clicked_Image"
                data-gtm-label="View Story">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.stories4
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </Link>
                <br />
                <p classname="pb-3">
                <Link
                to={"/stories-charting-a-course-for-luxury-and-innovation/" + url_param}
                data-gtm-category="BMW Stories"
                data-gtm-action="Clicked_Image"
                data-gtm-label="View Story">
                <strong>Charting a Course for Luxury and Innovation: Mr. Jean-Philippe Parain Headlines the Unveiling of the BMW Eurokars Experience Centre at the Eurokars Centre Launch</strong></Link>
                <br /><br />
                BMW Eurokars Auto proudly hosted Mr. Jean-Philippe Parain, BMW Group Senior Vice President of Sales Regions Asia-Pacific, Eastern Europe, Middle East, and Africa, at the grand launch of the Eurokars Centre on 15 December 2023.<br /><br />
                </p>
            </div>
            <div className="col-12 col-md-4">
                <br />
                <p classname="pb-3">
                &nbsp;
                </p>
            </div>
            <div className="col-12 col-md-4">
                <br />
                <p classname="pb-3">
                &nbsp;
                </p>
            </div>
        </div>
        

        {/* <p className="font-sm mt-3">*Terms and conditions apply: Deals mentioned are applicable for selected models only. Car specifications may vary from the model shown. Options and features are model-dependent. This page provides information on BMW products and does not constitute an offer of sale. A sale is subject to our applicable terms and conditions.</p> */}
        
      </div>
      
    </section>
      <Footer urlParam={url_param} />
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "stories" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }
            stories1 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            stories2 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            stories3 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            stories4 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default Stories

const seoDetails = {
  title: "Eurokars Auto | BMW Eurokars Stories",
  description:
    "As an official dealer of BMW, Eurokars Auto offers the full range of BMW vehicles – from fully electric BMW i models to high-performance BMW M models.",
  keywords: "Eurokars, Eurokars Auto, BMW, BMW Eurokars Auto",
  image: "og-eka-about-us.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
